<template>
	<section class="company-section case-studies">
    <div class="inner-container">
      <h2 class="company-header">Software Development Company – read more about us!</h2>
      <div class="case-studies-slider">
        <div class="case-studies-slider-item">
            <div class="case-studies-item">
              <div class="case-studies-img case-company">
                <img
                  v-lazyload
                  data-url="/images/cp.webp"
                  alt="company"
                  title="company"
                />
              </div>
              <div class="case-studies-item-article">
                <div
                  class="description"
                >
                  <div class="case-studies-text">
                    <p>
                      DevBrother (LeadIntelligence) is a unique web development company in USA, Poland and Ukraine, because we are bringing a new generation of Remote Team Extension on the market - we've created one of the biggest European Software Engineers Database and set up all necessary support for remote work with our clients - office spaces, recruiting, hiring, management, administrative, infrastructure, etc., based on partnership with CoWorking Spaces.
                    </p>
                  </div>
                </div>
                <div class="read-more-wrapper">
                  <router-link
                    to="/about"
                    class="read-more"
                    aria-label="about"
                    >Read more</router-link
                  >
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </section>
</template>
<script>
let vm = this;
export default {
  name: 'Company',
    components: {},
	data(){
    return {}
  },
  methods: {},
  mounted() {},
  beforeCreate() {
    vm = this;
  },
}
</script>
<style lang="scss" scoped>

.company-header {
  text-transform: none;
}

.company-section {
  .case-studies-slider {
    padding-top: 0px;
  }
}

.company-section .case-company {
  width: 460px;
  height: 385px;
}
</style>