<template>
    <div class="section-not_found">
        <img src="/img/images-db/images/bg_img/img v8-1_0000.webp" alt="photo" height="1545" width="869"
            class="career-stone-bg">
        <h1>404 - Page not found.</h1>
        <h2>You didn't find what you were looking for, but we have something else for you.</h2>

        <section class="main-404">
            <ul class="footer-submenu">
                <li>Quick links</li>
                <li class="marked-item">
                    <router-link to="/services/web3-development">Web3 Development</router-link>
                </li>
                <li class="marked-item">
                    <router-link to="/services/web-development">Web Development</router-link>
                </li>
                <li class="marked-item">
                    <router-link to="/services/mobile-development">Mobile Development</router-link>
                </li>
                <li class="marked-item">
                    <router-link to="/services/qa-automation">QA Automation & Testing</router-link>
                </li>
                <li class="marked-item">
                    <router-link to="/services/devops">DevOps</router-link>
                </li>
                <li class="marked-item">
                    <router-link to="/services/ui-ux-user-testing">UI/UX & User Testing</router-link>
                </li>
                <li class="marked-item">
                    <router-link to="/blog">Blog</router-link>
                </li>
            </ul>

            <img class="arrow-404" src="/img/arrow-404.svg" width="100" height="100" alt="arrow">
            <ServicesTopForm />
        </section>
        <router-link class="btn" to="/">go to homepage</router-link>

    </div>
</template>

<script>

import ServicesTopForm from '../ServicesTopForm';

export default {
    name: 'not-found',
    metaInfo: {
      meta: [
        { name: 'robots', content: 'noindex, nofollow' },
        { 'http-equiv': 'Cache-Control', content: 'no-cache, no-store, must-revalidate' },
        { 'http-equiv': 'Pragma', content: 'no-cache' },
        { 'http-equiv': 'Expires', content: '0' }
      ]
    },
    data() {
        return {

        }
    },
    components: {
        ServicesTopForm
    },
}
</script>
<style lang="scss" scoped>
.section-not_found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    padding-top: 150px;
    padding-bottom: 100px;
    background: radial-gradient(#1a455a, #1c3348, #1d2836, #1f2427);
}

.arrow-404 {
    width: 100px;
    height: auto;
    padding: 0;
    transform: rotate(180deg);
}

h1 {
    font-size: 50px;
    line-height: 1.2;
    color: #06c6ff;
}

h2 {
    top: 0;
    max-width: 750px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    font-family: Montserrat;
    font-size: 32px;
    letter-spacing: 2.13px;
    text-align: center;
    text-transform: none;
}

ul {
    height: fit-content;
    padding: 30px 20px;
    border-radius: 4px;
    background: linear-gradient(342.18deg, #1b222a 0%, #2b506e 100%);

    li:first-child {
        text-align: center;
        margin-bottom: 25px;
        font-size: 24px;
        text-transform: capitalize;
        color: #06c6ff;
        font-weight: bold;
    }

    li.marked-item {
        &::before {
            top: 12px;
        }

        &::after {
            top: 14px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

a {
    color: #FFFFFF;
}

a:hover {
    color: #06c6ff;
}

.btn {
    text-transform: uppercase;
    color: #1f2427;

    &:hover {
        color: #ffffff;
    }
}

.main-404 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 900px;
    width: 100%;
    margin-bottom: 80px;
    padding-left: 15px;
    padding-right: 15px;
}

.career-stone-bg {
    position: absolute;
    width: 1300px;
    height: auto;
    right: -200px;
    top: 100px;
    opacity: 0.6;
    z-index: -1;
}

@media (max-width: 1440px) {
    .career-stone-bg {
        width: 900px;
    }
}

@media (max-width: 1024px) {
    .career-stone-bg {
        display: none;
    }
}

@media (max-width: 768px) {
    .main-404 {
        justify-content: space-around;
    }

    .arrow-404 {
        width: 50px;
    }
}

@media (max-width: 767px) {

    .section-not_found {
        padding-top: 120px;
    }

    h1 {
        font-size: 36px;
        text-align: center;
    }

    h2 {
        font-size: 22px;
        line-height: 1.3;
        padding-left: 10px;
        padding-right: 10px;
    }

    .main-404 {
        flex-direction: column;
    }

    .arrow-404 {
        margin-top: 30px;
        margin-bottom: 30px;
        transform: rotate(-90deg);
    }
}
</style>
