<template>
<section class="section-main-db">
    <div class="promo">
      <div class="container-db">
        <div class="promo-text">
          <h1>Web Development Company</h1>
          <h2 class="promo-text-1">your software engineering family</h2>
          <div class="promo-text-2-wrapper">
            <div class="promo-text-2">always focused on your real result</div>
            <!--<router-link to="/#contact" class="btn" @click.native="scrollFix('#contact')">Let’s talk</router-link>-->
            <a @click="showPopup" class="btn hoverItem" aria-label="open contact form">Let’s talk</a>
            <div id="parallax">
              <div class="stone-layer4 layer parallax" id="keyart-0" data-speed="4">
                <img v-lazyload :data-url="'/img/images-db/parallax/layer5.'+this.$store.state.imageFormat" height="99" width="99" alt="layer5">
              </div>
              <div class="stone-layer2 layer parallax" id="keyart-1" data-speed="1">
                <img v-lazyload :data-url="'/img/images-db/parallax/layer3.'+this.$store.state.imageFormat" height="237" width="246" alt="layer3">
              </div>
              <div class="stone-layer3 layer parallax" id="keyart-2" data-speed="1">
                <img v-lazyload :data-url="'/img/images-db/parallax/layer4.'+this.$store.state.imageFormat" height="177" width="177" alt="layer4">
              </div>
              <div class="stone-layer1 layer parallax" id="keyart-3" data-speed="4">
                <img v-lazyload :data-url="'/img/images-db/parallax/layer2.'+this.$store.state.imageFormat" height="523" width="424" alt="layer2">
              </div>
              <div class="shadow4"></div>
              <div class="shadow2"></div>
              <div class="shadow3"></div>
              <div class="shadow1"></div>
            </div>
          </div>
        </div>
      </div>
      <Awards />
    </div>
    <div class="popup" :class="{ 'hide-popup': !showModal}">
      <div class="popup-wrapper">
        <div class="popup-inner" @click.self="closePopup()">
          <div class="popup-content" id="main-contact-form">
            <button class="close-popup" @click="closePopup()" type="button" aria-label="close popup"></button>
            <AcquaintedForm :state="showModal"/>
          </div>
        </div>
      </div>
	  </div>
</section>
</template>

<script>
  import VueAos from 'vue-aos';
  import AcquaintedForm from '../partials/AcquaintedForm';
  import Awards from '../partials/Awards';
  let vm = {};

  export default {
        name: 'main-section',
        metaInfo: {
            title: 'Web Development Company Services | DevBrother',
            meta: [
              {
                vmid: "description",
                name: 'description',
                content: 'Best Web Development Agency. Our Services: Mobile & Web Projects, Staff Augmentation, DevOps, QA Automation. Choose an experienced software development partner.'
              },
              {
                vmid: "og:image:type",
                name: "og:image:type",
                content: 'image/png'
              },
              {
                vmid: "og:title",
                name: "og:title",
                content: 'Web Development Company Services | DevBrother'
              },
              {
                vmid: "og:description",
                name: "og:description",
                content: 'Best Web Development Agency. Our Services: Mobile & Web Projects, Staff Augmentation, DevOps, QA Automation. Choose an experienced software development partner.'
              },
              {
                vmid: "og:image",
                name: "og:image",
                content: 'http://devbrother.com/img/images-db/Logo.png'
              },
              {
                vmid: "og:type",
                name: "og:type",
                content: "website"
              },
              {
                vmid: "og:url",
                name: "og:url",
                content: 'https://devbrother.com'
              },
              {
                vmid: "twitter:title",
                name: "twitter:title",
                content: 'Web Development Company Services | DevBrother'
              },
              {
                vmid: "twitter:description",
                name: "twitter:description",
                content: 'Best Web Development Agency. Our Services: Mobile & Web Projects, Staff Augmentation, DevOps, QA Automation. Choose an experienced software development partner.'
              },
              {
                vmid: "twitter:image",
                name: "twitter:image",
                content: 'http://devbrother.com/img/images-db/Logo.png'
              }
            ]
        },
        components: {
          AcquaintedForm,
          Awards
        },
        data() {
            return {
              loading: true,
              loadingSecond: false,
              firstTitle: false,
              secondTitle: false,
              thirdTitle: false,
              showModal: false,
            }
        },

        beforeCreate(){
            vm = this;
        },
        mounted: () => {
              function parallax(event) {
                const layer1 = document.querySelector(".stone-layer1");
                const layer2 = document.querySelector(".stone-layer2");
                const layer3 = document.querySelector(".stone-layer3");
                const layer4 = document.querySelector(".stone-layer4");
                const shadow1 = document.querySelector(".shadow1");
                const shadow2 = document.querySelector(".shadow2");
                const shadow3 = document.querySelector(".shadow3");
                const shadow4 = document.querySelector(".shadow4");

                if (layer1 !== null) {
                  layer1.style.transform = `translateY(${pageYOffset / 8}px)`;
                  layer2.style.transform = `translateY(-${pageYOffset / 5}px)`;
                  layer3.style.transform = `translateY(-${pageYOffset / 5}px)`;
                  layer4.style.transform = `translateY(-${pageYOffset / 8}px)`;

                  shadow1.style.transform = `translateY(-${pageYOffset / 10}px)`;
                  shadow2.style.transform = `translateY(-${pageYOffset / 8}px)`;
                  shadow3.style.transform = `translateY(-${pageYOffset / 8}px)`;
                  shadow4.style.transform = `translateY(-${pageYOffset / 8}px)`;
                }
            }

            document.addEventListener("scroll", parallax);
            vm.body =  document.querySelector("body");

            vm.addCanonicalLink();
        },

        methods: {
            addCanonicalLink: () => {
                const link = document.createElement('link');
                link.setAttribute('rel', 'canonical');
                link.setAttribute('href', 'https://devbrother.com');

                document.head.appendChild(link);
            },
            showPopup: () => {
              vm.showModal = true;
              vm.body.classList.add('ban-scroll');
            },
            closePopup: () => {
              vm.showModal = false;
              vm.body.classList.remove('ban-scroll');
            },
            scrollFix: hash => {
              setTimeout(() => {
                setTimeout(() => $('html, body')
                .animate({scrollTop: $(hash).offset().top}, 1000), 1)
              });
            }
        }
    }
</script>

<style scoped>

  .hoverItem {
    cursor: pointer;
  }

</style>
