<template>
  <!--SECTION INDUSTRIES-->
  <main>
    <div class="top-stone">
    </div>
    <div class="pagination">
      <div class="container-db">
        <div class="bread-crumbs">
          <router-link to="/">Home</router-link>
          <span class="bread-separator">/</span>
          <span class="bread-current">Web Development</span>
        </div>
      </div>
    </div>

    <section class="service-description">
      <div class="container-db">
        <div class="service-wrapper">
          <div class="service-text-wrapper">
            <h1>Web Development Agency - Customized Services</h1>
            <p>
              Web Development is one of the most significant parts of DevBrother services - we do a wide range of
              software, from casual online e-commerce and news websites to big and complicated online startups that have a
              similar scale to Airbnb or ZocDoc web portals. Working only with highly qualified and professional
              engineers, we deliver your idea to the market at the top level, from understanding the concept and choosing
              the proper technologies for every particular case to launch a well-tested and stable product.</p>
          </div>

          <div class="stone-image-wrapper">
            <div class="service-contact">
              <p class="service-contact__title">Free consultation</p>
              <p>We advise choosing the right technology and strategies for developing a new product.</p>
              <p>Let's arrange a short phone or video call? We will listen to your wishes and select a team of
                professionals to implement the product.</p>
              <button @click="showPopup" class="btn form-btn">Contact us</button>
            </div>
            <div class="top-stone mobile">
            </div>
          </div>
        </div>

        <div class="service-text-wrapper our-web-related-technologies">
          <h2>Our Web related Technologies</h2>
          <p>
            Custom web development requires different technologies. Depending on your needs, we can analyze and select the
            best tools. Primarily we work with the following web-related technologies: JavaScript, TypeScript, PHP, Ruby,
            C#, Python, and Go.
          </p>
          <div class="three-list-wrapper">
            <div class="list-wrapper">
              <h3>Javascript</h3>
              <ul class="bullet-list">
                <li class="marked-item router-link-active"><router-link
                    to="/services/web-development/react">ReactJS</router-link></li>
                <!-- <li class="marked-item router-link-active"><router-link to="/services/web-development/vue">VueJS</router-link></li> -->
                <li class="marked-item">VueJS</li>
                <li class="marked-item router-link-active"><router-link
                    to="/services/web-development/node">NodeJS</router-link></li>
                <li class="marked-item">TypeScript</li>
              </ul>
            </div>

            <div class="list-wrapper">
              <h3>PHP</h3>
              <div class="list-title">CMS:</div>
              <ul class="bullet-list">
                <li class="marked-item">WordPress</li>
                <li class="marked-item">OpenCart</li>
              </ul>
              <div class="list-title">Frameworks:</div>
              <ul class="bullet-list">
                <li class="marked-item">Laravel</li>
              </ul>
            </div>

            <div class="list-wrapper">
              <h3>C#</h3>
              <ul class="bullet-list">
                <li class="marked-item">.NET</li>
                <li class="marked-item">ASP.NET</li>
              </ul>
            </div>

            <div class="list-wrapper">
              <h3>Python</h3>
              <ul class="bullet-list">
                <li class="marked-item">Django</li>
                <li class="marked-item">Flask</li>
              </ul>
            </div>

            <!-- <div class="list-wrapper">
              <h3>Ruby</h3>
              <ul class="bullet-list">
                <li class="marked-item">Ruby on Rails</li>
              </ul>
              <h3>Go</h3>
            </div> -->

            <div class="list-wrapper">
              <h3>Ruby</h3>
              <ul class="bullet-list">
                <li class="marked-item">Ruby on Rails</li>
              </ul>
            </div>
            <div class="list-wrapper">
              <h3>Go</h3>
            </div>


          </div>
        </div>
      </div>

      <div class="popular-request">
        <div class="popular-request-wrapper container-db">
          <div class="service-text-wrapper requests">
            <h2>What we can build for you</h2>
            <p>
              DevBrother can implement everything that can be seen on the web - from casual and classic solutions to
              custom and complicated ones. Below are some of our most popular requests. </p>
            <ul class="bullet-list">
              <li class="marked-item">
                <span>Landing pages and Online Business cards:</span> The fastest and the easiest solution for our
                clients to represent them online
              </li>
              <li class="marked-item">
                <span>Web portals and Web blogs:</span> Websites that connected to news, information, descriptions -
                actually when client needs to have an opportunity to manage the content on the website fast and easy.
              </li>
              <li class="marked-item">
                <span>E­commerce:</span> Online payments - is one of our favorite areas. We can propose lots of solutions
                for e-commerce website also with crypto payments and subscriptions.
              </li>
              <li class="marked-item">
                <span>Web applications:</span> We create web applications of different difficulty levels. Our database
                architects will implement the proper fundament to your project and devops engineers will take care of
                it’s stable work with any kind of load. These apps may be stand­alone or can be a part of other
                application for integration and vice versa. We are proud that we’ve built several world level projects
                (similar to Airbnb and ZocDoc).
              </li>
            </ul>
            <p class="more-about">
              To learn more about our web development services and projects you can visit our <router-link
                to="/#case-studies">portfolio</router-link> or <a href="mailto:contact@devbrother.com">contact us.</a>
            </p>
          </div>
        </div>
        <div class="popular-request-img">
          <img v-lazyload data-url="/img/images-db/images/e-commerce.webp" alt="e-commerce.webp" width="915" height="665">
        </div>
      </div>
    </section>
    <AcquaintedSection />
    <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>

    <div class="popup" :class="{ 'hide-popup': !showModal }">
      <div class="popup-wrapper">
        <div class="popup-inner" @click.self="closePopup()">
          <div class="popup-content" id="service-contact-form">
            <button class="close-popup" @click="closePopup()" type="button" aria-label="close popup"></button>
            <ServicesTopForm />
          </div>
        </div>
      </div>
    </div>

    <script v-html="jsonld" type="application/ld+json"></script>
  </main>
</template>
<script>
import AcquaintedSection from '../AcquaintedSection';
import ServicesTopForm from '../ServicesTopForm';

let vm = {};
export default {
  name: 'Webdev',
  metaInfo: {
    title: 'Web Development Agency - Custom Outsourced Services ➜ DevBrother',
    meta: [
      {
        vmid: "description",
        name: 'description',
        content: 'DevBrother ➜ Experienced Web Development Agency ➜ Company in which the price matches the quality, and the work is carried out with an individual approach.'
      },
      {
        vmid: "og:image:type",
        name: "og:image:type",
        content: 'image/webp'
      },
      {
        vmid: "og:title",
        name: "og:title",
        content: 'Web Development Agency - Custom Outsourced Services ➜ DevBrother'
      },
      {
        vmid: "og:description",
        name: "og:description",
        content: 'DevBrother ➜ Experienced Web Development Agency ➜ Company in which the price matches the quality, and the work is carried out with an individual approach.'
      },
      {
        vmid: "og:image",
        name: "og:image",
        content: 'https://devbrother.com/img/images-db/images/e-commerce.webp'
      },
      {
        vmid: "og:type",
        name: "og:type",
        content: "website"
      },
      {
        vmid: "og:url",
        name: "og:url",
        content: 'https://devbrother.com/services/web-development'
      },
      {
        vmid: "twitter:title",
        name: "twitter:title",
        content: 'Web Development Agency - Custom Outsourced Services ➜ DevBrother'
      },
      {
        vmid: "twitter:description",
        name: "twitter:description",
        content: 'DevBrother ➜ Experienced Web Development Agency ➜ Company in which the price matches the quality, and the work is carried out with an individual approach.'
      },
      {
        vmid: "twitter:image",
        name: "twitter:image",
        content: 'https://devbrother.com/img/images-db/images/e-commerce.webp'
      }
    ]
  },
  components: {
    AcquaintedSection,
    ServicesTopForm
  },
  data() {
    const jsonld = {
      "@context": "https://schema.org",
      "@type": "Service",
      "name": "Web Development Agency - Custom Outsourced Services ➜ DevBrother",
      "description": "DevBrother ➜ Experienced Web Development Agency ➜ Company in which the price matches the quality, and the work is carried out with an individual approach."
    }

    return {
      jsonld,
      showModal: false,
    }
  },
  beforeCreate() {
    vm = this;
  },
    mounted: () => {
            vm.body = document.querySelector("body");
            vm.addCanonicalLink();
    },
    methods: {
            addCanonicalLink: () => {
              const link = document.createElement('link');
              link.setAttribute('rel', 'canonical');
              link.setAttribute('href', 'https://devbrother.com/services/web-development');

              const oldLink = document.querySelector('link[rel="canonical"]');
              if (oldLink) {
                document.head.removeChild(oldLink);
              }

              document.head.appendChild(link);
            },
            showPopup: () => {
              vm.showModal = true;
              vm.body.classList.add('ban-scroll');
            },
            closePopup: () => {
              vm.showModal = false;
              vm.body.classList.remove('ban-scroll');
            },
            scrollFix: hash => {
              setTimeout(() => {
                setTimeout(() => $('html, body')
                  .animate({ scrollTop: $(hash).offset().top }, 1000), 1)
              });
            },
  }
}
</script>
<style lang="scss" scoped>
.three-list-wrapper {
  justify-content: flex-start;

  .list-wrapper {
    margin-bottom: 15px;
  }
}

/* @media (max-width: 1024px) {
  h2 {
    font-size: 40px;
    letter-spacing: 0.9px;
    line-height: 47px;
  }
} */</style>
