<template>
  <!--SECTION INDUSTRIES-->
  <main>
    <div class="top-stone">
    </div>
    <div class="pagination">
      <div class="container-db">
        <div class="bread-crumbs">
          <router-link to="/">Home</router-link>
          <span class="bread-separator">/</span>
          <span class="bread-current">Web Development</span>
        </div>
      </div>
    </div>

    <section class="service-description">
      <div class="container-db">
        <div class="service-wrapper">
          <div class="service-text-wrapper">
            <h1>Custom Web Application Development Company</h1>
            <p>
              Web development is one of the most significant parts of DevBrother's software development company. We create a wide range of software, from casual online e-commerce and news websites to complex and large-scale startups similar to Airbnb or ZocDoc. By collaborating with a custom web development company, we ensure that your idea is brought to life precisely— from conceptualization and selecting the right technologies to launching a well-tested, stable product.</p>
          </div>

          <div class="stone-image-wrapper">
            <div class="service-contact">
              <p class="service-contact__title">Free consultation</p>
              <p>We advise choosing the right technology and strategies for developing a new product.</p>
              <p>Let's arrange a short phone or video call? We will listen to your wishes and select a team of
                professionals to implement the product.</p>
              <button @click="showPopup" class="btn form-btn">Contact us</button>
            </div>
            <div class="top-stone mobile">
            </div>
          </div>
        </div>

        <div class="service-text-wrapper our-web-related-technologies">
          <h2>Our Web-related Technologies</h2>
          <p>
            Custom web app development requires various technologies. Depending on your specific requirements, we analyze and select the best tools. Our primary web-related technologies include JavaScript, TypeScript, PHP, Ruby, C#, Python, and Go.
          </p>
          <div class="three-list-wrapper">
            <div class="list-wrapper">
              <h3>Javascript</h3>
              <ul class="bullet-list">
                <li class="marked-item router-link-active"><router-link
                    to="/services/web-development/react">ReactJS</router-link></li>
                <!-- <li class="marked-item router-link-active"><router-link to="/services/web-development/vue">VueJS</router-link></li> -->
                <li class="marked-item">VueJS</li>
                <li class="marked-item router-link-active"><router-link
                    to="/services/web-development/node">NodeJS</router-link></li>
                <li class="marked-item">TypeScript</li>
              </ul>
            </div>

            <div class="list-wrapper">
              <h3>PHP</h3>
              <div class="list-title">CMS:</div>
              <ul class="bullet-list">
                <li class="marked-item">WordPress</li>
              </ul>
              <div class="list-title">Frameworks:</div>
              <ul class="bullet-list">
                <li class="marked-item">Laravel</li>
              </ul>
            </div>

            <div class="list-wrapper">
              <h3>C#</h3>
              <ul class="bullet-list">
                <li class="marked-item">.NET</li>
                <li class="marked-item">ASP.NET</li>
              </ul>
            </div>

            <div class="list-wrapper">
              <h3>Python</h3>
              <ul class="bullet-list">
                <li class="marked-item">Django</li>
                <li class="marked-item">Flask</li>
              </ul>
            </div>

            <!-- <div class="list-wrapper">
              <h3>Ruby</h3>
              <ul class="bullet-list">
                <li class="marked-item">Ruby on Rails</li>
              </ul>
              <h3>Go</h3>
            </div> -->

            <div class="list-wrapper">
              <h3>Ruby</h3>
              <ul class="bullet-list">
                <li class="marked-item">Ruby on Rails</li>
              </ul>
            </div>
            <div class="list-wrapper">
              <h3>Go</h3>
            </div>


          </div>
        </div>
      </div>

      <div class="popular-request">
        <div class="popular-request-wrapper container-db">
          <div class="service-text-wrapper requests">
            <h2>What we can build for you</h2>
            <p>
              DevBrother is a custom web development company in the USA that can implement everything seen on the Web—from standard solutions to intricate and highly customized applications. Here are some of our most popular requests </p>
            <ul class="bullet-list">
              <li class="marked-item">
                <span>Landing Pages and Online Business Cards:</span> The fastest and easiest solution for our clients to establish an online presence.
              </li>
              <li class="marked-item">
                <span>Web Portals and Web Blogs:</span> Ideal for clients who need fast and straightforward content management.
              </li>
              <li class="marked-item">
                <span>E-commerce and Online Payments:</span> Our expertise in e-commerce includes crypto payments and subscription models, which allow us to offer flexible and secure online shopping experiences.
              </li>
              <li class="marked-item">
                <span>Web Applications:</span> We develop applications of varying complexity, supported by robust database architectures and stable DevOps practices. Our projects range from stand-alone applications to systems integrated with other tools, including world-level platforms like Airbnb and ZocDoc.
              </li>
            </ul>
            <p class="more-about">
              To learn more about our web development services and projects you can visit our <router-link
                to="/#case-studies">portfolio</router-link> or <a href="mailto:contact@devbrother.com">contact us.</a>
            </p>
          </div>
        </div>
        <div class="popular-request-img">
          <img v-lazyload data-url="/img/images-db/images/e-commerce.webp" alt="e-commerce.webp" width="915" height="665">
        </div>
      </div>
    </section>
    <section class="dedicated-section service-description service-consulting">
      <div class="container-db popular-request">
        <div class="service-wrapper">
          <div class="service-text-wrapper service-steps">
            <h2>Advanced Cybersecurity Measures for Web App Protection</h2>
            <ol>
              <li><span class="marked">Robust Authentication Mechanisms.</span> Multi-factor authentication and secure login protocols.</li>
              <li><span class="marked">Password Hashing Techniques.</span> Using algorithms like Bcrypt or Argon2 to protect credentials.</li>
              <li><span class="marked">Data Masking and Obfuscation.</span> Advanced methods to safeguard sensitive data.</li>
              <li><span class="marked">SQL Injection Mitigation.</span> Secure coding and input validation.</li>
              <li><span class="marked">SSL/TLS Encryption.</span> Protecting data transmissions with encryption.</li>
              <li><span class="marked">Cross-Site Scripting (XSS) Prevention.</span> Content security policies and input sanitization.</li>
              <li><span class="marked">Third-Party Library Security Audits.</span> Regular audits and prompt patching.</li>
              <li><span class="marked">Comprehensive Penetration Testing.</span> Identifying and resolving security vulnerabilities.</li>
            </ol>
          </div>
        </div>
      </div>

    </section>
    <section class="dedicated-section service-description service-benefits">
      <div class="container-db popular-request">
        <div class="service-wrapper">
          <div class="service-text-wrapper service-steps">
            <h2>Why Work With Us?</h2>
            <p>Our clients trust us as a <b>customer service outsourcing company</b> because of our commitment to data security, focus on quality, and innovative approach to complex challenges.</p>
            <ul class="bullet-list">
              <li class="marked-item"><b>Aligned with Your Business Goals.</b> We prioritize your objectives to deliver results that drive success.</li>
              <li class="marked-item"><b>Proven Delivery Excellence.</b> Smooth and consistent project execution with refined processes.</li>
              <li class="marked-item"><b>Clear Communication and Workflow.</b> Transparent communication and structured approaches ensure your involvement at all stages of development.</li>
              <li class="marked-item"><b>On-Time and On-Budget.</b> We adhere to timelines and budgets, delivering precisely what you expect.</li>
            </ul>
          </div>
        </div>
        <div class="service-text-wrapper service-stages">
          <h2>How We Develop Custom Web Applications</h2>
          <p>At Devbrother, a custom web development company, our streamlined process minimizes development time while ensuring high-quality product delivery.</p>
          <h3>Stage 1. Product Discovery and Ideation</h3>
          <p>
            Our first step is to identify a product that meets customer needs, offers business value, and is feasible to develop and launch.
          </p>
          <ul>
            <li class="marked-item">Market research and analysis</li>
            <li class="marked-item">Business model definition</li>
            <li class="marked-item">Product scope identification</li>
            <li class="marked-item">Technology stack selection</li>
            <li class="marked-item">Baseline schedule and budget creation</li>
            <li class="marked-item">PoC or MVP strategy development</li>
          </ul>
          <h3>Stage 2. Product Design</h3>
          <p>We transform the product concept into a detailed design ready for development and market launch.</p>
          <ul>
            <li class="marked-item">Product requirements identification</li>
            <li class="marked-item">Business and design requirements development</li>
            <li class="marked-item">Non-functional requirements creation</li>
            <li class="marked-item">Data model crafting</li>
            <li class="marked-item">Functional requirements establishment</li>
            <li class="marked-item">UX research and design architecture</li>
            <li class="marked-item"><router-link to="/services/ui-ux-user-testing">UX/UI design</router-link></li>
          </ul>
          <h3>Stage 3. Backend Development</h3>
          <p>We build the server-side architecture and secure backend API using top-tier technologies.</p>
          <ul>
            <li class="marked-item">Server-side architecture development</li>
            <li class="marked-item">Environment configuration</li>
            <li class="marked-item">Backend API development and security</li>
            <li class="marked-item">Comprehensive API documentation</li>
            <li class="marked-item">Scalability and auto-recovery features</li>
          </ul>
          <h3>Stage 4. Frontend Development</h3>
          <p>We develop the client-side architecture and ensure seamless user interaction.</p>
          <ul>
            <li class="marked-item">Client-side architecture design</li>
            <li class="marked-item">Environment configuration</li>
            <li class="marked-item">Client-side application development</li>
            <li class="marked-item">REST or GraphQL API documentation</li>
          </ul>
          <h3>Stage 5. Infrastructure Management and DevOps</h3>
          <p>We set up a reliable and secure cloud infrastructure to ensure smooth application performance.</p>
          <ul>
            <li class="marked-item"><router-link to="/services/devops">DevOps</router-link> practices selection</li>
            <li class="marked-item">Infrastructure as code implementation</li>
            <li class="marked-item">Cloud migration</li>
            <li class="marked-item">One-click deployment setup</li>
          </ul>
          <h3>Stage 6. Quality Control Before</h3>
          <p>Launch, we conduct rigorous testing to ensure performance and stability.</p>
          <ul>
            <li class="marked-item">Test approaches definition</li>
            <li class="marked-item">Test plans and case development</li>
            <li class="marked-item">Functional, load, and performance testing</li>
            <li class="marked-item">Compatibility, localization, and security testing</li>
          </ul>
          <h3>Stage 7. Product Support and Maintenance Post-launch</h3>
          <p>We ensure the application remains secure, up-to-date, and fully functional.</p>
          <ul>
            <li class="marked-item">Maintenance environment configuration</li>
            <li class="marked-item">Continuous analytics and monitoring setup</li>
            <li class="marked-item">Issue detection systems implementation</li>
            <li class="marked-item">Customer support systems establishment</li>
            <li class="marked-item">Regular updates, security patches, and bug fixes</li>
          </ul>
        </div>
      </div>
    </section>
    <section class="outsource-read-more">
      <div class="container-db">
        <div class="inner-container">
          <h2 class="outsource-read-more-header">Read More About Us!</h2>
          <Company />
        </div>
      </div>
    </section>

    <section class="faq-section">
      <h2>Frequently Asked Questions</h2>
      <div class="container-db">
        <div id="app">
          <app-accordion @closeAccordions='closeAccordions()' v-for="(accordion, index) in accordions"
                         ref="accordion" :key="index" class="app-accordion">
            <template v-slot:title>
              <span>{{ accordion.title }}</span>
            </template>
            <template v-slot:content>
              <p>
                {{ accordion.text }}
              </p>
            </template>
          </app-accordion>
        </div>
      </div>
    </section>

    <AcquaintedSection />
    <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>

    <div class="popup" :class="{ 'hide-popup': !showModal }">
      <div class="popup-wrapper">
        <div class="popup-inner" @click.self="closePopup()">
          <div class="popup-content" id="service-contact-form">
            <button class="close-popup" @click="closePopup()" type="button" aria-label="close popup"></button>
            <ServicesTopForm />
          </div>
        </div>
      </div>
    </div>

    <script v-html="jsonld" type="application/ld+json"></script>
  </main>
</template>
<script>
import AcquaintedSection from '../AcquaintedSection';
import ServicesTopForm from '../ServicesTopForm';
import Company from '../Home/Company';
import AppAccordion from "./web-development/AppAccordion.vue";

let vm = {};
export default {
  name: 'Webdev',
  metaInfo: {
    title: 'Custom Web Development Company - Outsourced Services ➜ DevBrother',
    meta: [
      {
        vmid: "description",
        name: 'description',
        content: 'Customer Service Outsourced ➜ Web Development Company in which the price matches the quality, and the work is carried out with an individual approach.'
      },
      {
        vmid: "og:image:type",
        name: "og:image:type",
        content: 'image/webp'
      },
      {
        vmid: "og:title",
        name: "og:title",
        content: 'Custom Web Development Company - Outsourced Services ➜ DevBrother'
      },
      {
        vmid: "og:description",
        name: "og:description",
        content: 'Customer Service Outsourced ➜ Web Development Company in which the price matches the quality, and the work is carried out with an individual approach.'
      },
      {
        vmid: "og:image",
        name: "og:image",
        content: 'https://devbrother.com/img/images-db/images/e-commerce.webp'
      },
      {
        vmid: "og:type",
        name: "og:type",
        content: "website"
      },
      {
        vmid: "og:url",
        name: "og:url",
        content: 'https://devbrother.com/services/web-development'
      },
      {
        vmid: "twitter:title",
        name: "twitter:title",
        content: 'Custom Web Development Company - Outsourced Services ➜ DevBrother'
      },
      {
        vmid: "twitter:description",
        name: "twitter:description",
        content: 'Customer Service Outsourced ➜ Web Development Company in which the price matches the quality, and the work is carried out with an individual approach.'
      },
      {
        vmid: "twitter:image",
        name: "twitter:image",
        content: 'https://devbrother.com/img/images-db/images/e-commerce.webp'
      }
    ]
  },
  components: {
    AppAccordion,
    AcquaintedSection,
    ServicesTopForm,
    Company
  },
  data() {
    const jsonld = {
      "@context": "https://schema.org",
      "@type": "Service",
      "name": "Custom Web Development Company - Outsourced Services ➜ DevBrother",
      "description": "Customer Service Outsourced ➜ Web Development Company in which the price matches the quality, and the work is carried out with an individual approach."
    }

    return {
      jsonld,
      showModal: false,
      accordions: [
        {
          title: 'Can DevBrother help with modernizing existing websites?',
          text: "Yes, DevBrother, an experienced customer service outsource partner, transforms outdated websites to improve performance, user experience, and functionality. Whether you need a visual refresh, mobile optimization, or feature upgrades, our team ensures your site meets current industry standards."
        },
        {
          title: 'How does DevBrother ensure mobile responsiveness and cross-browser compatibility?',
          text: "We prioritize mobile-first design and perform extensive cross-browser testing. This ensures your website provides an optimal experience across devices and platforms, a key aspect of customer service outsourced projects."
        },
        {
          title: 'What is DevBrother\'s typical web development process?',
          text: "Our process involves thorough discovery, detailed design, agile development, rigorous testing, and continuous maintenance. To ensure success, we keep clients involved and informed at each step."
        },
        {
          title: 'How do I get started with DevBrother\'s web development services?',
          text: "Getting started is easy—contact us through our website, and we'll schedule a consultation to discuss your project goals and how our outsourcing of customer service and web development expertise can help you achieve them."
        },
      ],
    }
  },
  beforeCreate() {
    vm = this;
  },
    mounted: () => {
            vm.body = document.querySelector("body");
            vm.addCanonicalLink();
    },
    methods: {
            addCanonicalLink: () => {
              const link = document.createElement('link');
              link.setAttribute('rel', 'canonical');
              link.setAttribute('href', 'https://devbrother.com/services/web-development');

              const oldLink = document.querySelector('link[rel="canonical"]');
              if (oldLink) {
                document.head.removeChild(oldLink);
              }

              document.head.appendChild(link);
            },
            showPopup: () => {
              vm.showModal = true;
              vm.body.classList.add('ban-scroll');
            },
            closePopup: () => {
              vm.showModal = false;
              vm.body.classList.remove('ban-scroll');
            },
            scrollFix: hash => {
              setTimeout(() => {
                setTimeout(() => $('html, body')
                  .animate({ scrollTop: $(hash).offset().top }, 1000), 1)
              });
            },
            closeAccordions() {
              this.$refs.accordion.forEach(el => el.closeAccordion());
            },
  }
}
</script>
<style lang="scss" scoped>
.service-stages {
  h3 {
    margin-top: 30px;
  }
}

.faq-section {
  z-index: 10;
  position: relative;
  margin-top: 70px;
  margin-bottom: 100px;
}

.service-consulting {
  margin-bottom: -50px;
}

.three-list-wrapper {
  justify-content: flex-start;

  .list-wrapper {
    margin-bottom: 15px;
  }
}

.outsource-read-more {
  ::v-deep .company-header {
    display: none;
  }
}

.outsource-read-more-header {
  text-align: center;
  margin-bottom: -150px;
  margin-top: -50px;
}

.marked {
  color: #06c6ff;
}

.service-steps {
  ol {
    margin-left: 20px;
    list-style-type: auto;

    li {
      padding-left: 0;

      &::before {
        display: none;
      }

      &::marker {
        color: #06c6ff;
        font-size: 16px;
      }
    }
  }
}

/* @media (max-width: 1024px) {
  h2 {
    font-size: 40px;
    letter-spacing: 0.9px;
    line-height: 47px;
  }
} */</style>
