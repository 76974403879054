<template>
  <!--SECTION INDUSTRIES-->
  <main class="outsource-section">
    <div class="top-stone">
    </div>
    <div class="pagination">
      <div class="container-db">
        <div class="bread-crumbs">
          <router-link to="/" class="router-link-active">Home</router-link>
          <span class="bread-separator">/</span>
          <router-link to="/services/web-development">Web Development</router-link>
          <span class="bread-separator">/</span>
          <span class="bread-current">Outsource</span>
        </div>
      </div>
    </div>

    <section class="service-description">
      <div class="container-db">
        <div class="service-wrapper">
          <div class="service-text-wrapper">
            <h1>Outsource Web Development Services</h1>
            <p>
              In today's digital landscape, businesses of all sizes recognize the importance of a strong online presence. However, only some companies have the in-house resources or expertise to develop and maintain a high-quality website. This is where outsourced web development services come into play. As a leading software development outsourcing company, we offer comprehensive IT outsourcing services to help businesses thrive digitally.</p>
          </div>

          <div class="stone-image-wrapper">
            <div class="service-contact">
              <p class="service-contact__title">Free consultation</p>
              <p>We advise choosing the right technology and strategies for developing a new product.</p>
              <p>Let's arrange a short phone or video call? We will listen to your wishes and select a team of
                professionals to implement the product.</p>
              <button @click="showPopup" class="btn form-btn">Contact us</button>
            </div>
            <div class="top-stone mobile">
            </div>
          </div>
        </div>
      </div>
      <section class="benefits-section">
        <div class="benefits">
            <div class="inner-container">
              <h3 class="benefit-header">Outsource Web Development Services We Provide</h3>
              <p>Our IT outsourcing company specializes in a wide range of web development services, catering to diverse business needs. Here's an overview of our core offerings:</p>
              <ul class="benefits-list">
                <li class="benefits-list-item" data-aos="flip-left" data-aos-duration="3000">
                  <div class="inner-benefits-item">
                    <h3>Full-stack Software Development</h3>
                    <p class="sub-info">As an experienced outsourcing software development company, we provide end-to-end solutions that cover both front-end and back-end development. Our full-stack developers are proficient in various programming languages and frameworks, ensuring seamless integration of all components.</p>
                  </div>
                </li>
                <li class="benefits-list-item" data-aos="flip-left" data-aos-duration="3000">
                  <div class="inner-benefits-item">
                    <h3>Front-end Development</h3>
                    <p class="sub-info">Our front-end developers focus on creating intuitive, responsive, and visually appealing user interfaces. We utilize the latest technologies and best practices to deliver exceptional user experiences across all devices and platforms.</p>
                  </div>
                </li>
                <li class="benefits-list-item" data-aos="flip-left" data-aos-duration="3000">
                  <div class="inner-benefits-item">
                    <h3>Back-end Development</h3>
                    <p class="sub-info">Our back-end development team specializes in building robust server-side applications, APIs, and databases. We ensure your website's foundation is secure, scalable, and performant.</p>
                  </div>
                </li>
              </ul>
              <ul class="benefits-list">
                <li class="benefits-list-item" data-aos="flip-left" data-aos-duration="3000">
                  <div class="inner-benefits-item">
                    <h3>UI/UX Design</h3>
                    <p class="sub-info">Our designers create user-centric interfaces that look great and provide optimal usability. We focus on intuitive navigation and engaging visual elements that align with your brand identity.</p>
                  </div>
                </li>
                <li class="benefits-list-item" data-aos="flip-left" data-aos-duration="3000">
                  <div class="inner-benefits-item">
                    <h3>DevOps Services</h3>
                    <p class="sub-info">We offer comprehensive DevOps services to streamline your development and deployment processes. Our experts implement continuous integration and delivery pipelines, ensuring faster time-to-market and improved collaboration.</p>
                  </div>
                </li>
                <li class="benefits-list-item" data-aos="flip-left" data-aos-duration="3000">
                  <div class="inner-benefits-item">
                    <h3>Mobile App Development</h3>
                    <p class="sub-info">In addition to <router-link to="/services/web-development">web development</router-link>, we also offer mobile app development services. As an outsourced mobile app development company, we create native and cross-platform applications that extend your online presence to mobile devices.</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
      </section>

      <section class="software-benefits dedicated-section service-description outsource-process">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper key-benefits">
                        <h2>Our Outsource Web Development Process</h2>
                        <p>
                            At our offshore outsourcing software development company, we follow a structured approach to ensure the success of your project:
                        </p>
                        <div class="wrap">
                            <ol>
                                <li>
                                    <span class="marked">Defining project specification</span> We begin by thoroughly understanding your requirements, goals, and target audience. This crucial step helps us align our development efforts with your business objectives.
                                </li>
                                <li>
                                    <span class="marked">Creating a web design</span> Our designers create wireframes and mockups, iterating based on your feedback to ensure the final design meets your expectations.
                                </li>
                                <li>
                                    <span class="marked">Developing your solution</span> Our development team brings the design to life, coding your website or application's front-end and back-end components.
                                </li>
                                <li>
                                    <span class="marked">Ensuring code quality</span> Throughout the development process, we conduct rigorous testing to identify and resolve any issues, ensuring a high-quality final product.
                                </li>
                                <li>
                                    <span class="marked">Delivering and maintenance</span> After launch, we provide ongoing support and maintenance to keep your website running smoothly and up-to-date.
                                </li>
                            </ol>
                            <div class="img-wrap service-img-wrapper">
                              <img class="key-benefits-image" v-lazyload data-url="/img/images-db/services/outsource.webp"
                                        alt="outsource web development" height="350" width="525">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      <div class="service-text-wrapper our-web-related-technologies outsource-services">
          <h2>Technologies We Use</h2>
          <p>
            As a leading IT outsourcing software development company, we stay at the forefront of technology trends. Here are some of the key technologies we leverage:
          </p>
          <div class="three-list-wrapper">
            <div class="list-wrapper">
              <ul class="bullet-list">
                <li class="marked-item"><b>Back End</b> We use powerful back-end technologies such as <router-link to="/services/web-development/node">Node.js</router-link>, Python, Ruby on Rails, and PHP to build robust server-side applications.</li>
                <li class="marked-item"><b>Front End</b>
                  Our front-end developers are skilled in HTML5, CSS3, JavaScript, and popular frameworks like React, Angular, and Vue.js.
                </li>
                <li class="marked-item"><b>DevOps & Cloud</b>
                  We utilize cloud platforms like AWS, Azure, and Google Cloud, as well as containerization technologies like Docker and Kubernetes.
                </li>
              </ul>
            </div>

            <div class="list-wrapper">
              <ul class="bullet-list">
                <li class="marked-item"><b>Database Development</b>
                  We work with SQL and NoSQL databases, including MySQL, PostgreSQL, MongoDB, and Redis.
                </li>
                <li class="marked-item"><b>Web 3</b>
                  Our team is experienced in <router-link to="/industries/cryptocurrency-development">blockchain technologies</router-link> and decentralized application development.
                </li>
                <li class="marked-item"><b>Artificial Intelligence</b>
                  We integrate AI and machine learning capabilities to enhance website functionality and user experiences.
                </li>
              </ul>
            </div>
          </div>
      </div>

      <section class="outsource-other">
        <div class="container-db">
            <div class="inner-container">
                <h2>Other</h2>
                <Technologies />
            </div>
        </div>
      </section>

      <section class="industries-section service-description service-process">
          <div class="container-db">
            <div class="service-wrapper">
              <div class="service-text-wrapper service-steps">
                <h2>Why Outsourcing Website Development by DevBrother?</h2>
                <p>When you choose our outsourcing development company, you partner with a team dedicated to your success. Here's why DevBrother stands out:</p>
                <ol>
                  <li><span class="marked">Why Choose DevBrother</span> We combine technical expertise with business acumen to deliver solutions that drive actual results. Our team of skilled professionals is committed to excellence in every project we undertake.</li>
                  <li><span class="marked">Cost-Effective Relationships</span> Outsourcing your web development to us can significantly reduce your operational costs. We offer competitive rates without compromising quality, allowing you to allocate resources more efficiently.</li>
                  <li><span class="marked">Time Difference</span> Our global presence allows us to work around the clock, potentially speeding up your project timeline. We adapt to your schedule and ensure seamless communication despite time zone differences.</li>
                  <li><span class="marked">Profound Data Security</span> As an IT outsourcing company, we understand the importance of data security. We implement rigorous security measures and follow best practices to protect your sensitive information.</li>
                  <li><span class="marked">High Level Of English</span> Clear communication is crucial for project success. Our team boasts excellent English skills, ensuring smooth collaboration and minimizing misunderstandings.</li>
                  <li><span class="marked">Constant Learning</span> The tech world evolves rapidly, and so do we. Our developers continuously update their skills and knowledge to provide the latest and most effective solutions.</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
      
      <section class="outsource-read-more">
        <div class="container-db">
            <div class="inner-container">
              <h2 class="outsource-read-more-header">Read More About Us!</h2>
              <Company/>
            </div>
        </div>
      </section>

      <section class="industries-section service-description service-process how-to-section">
          <div class="container-db">
            <div class="service-wrapper">
              <div class="service-text-wrapper service-steps">
                <h2>How to Outsource Web Development</h2>
                <p>If you're considering outsourcing your web development, here's a step-by-step guide to help you through the process:</p>
                <ol>
                  <li><span class="marked">Define your needs</span> Clearly outline your project requirements, including functionalities, design preferences, and any specific technologies you want to use.</li>
                  <li><span class="marked">Decide on budget and timeline</span> Determine how much you're willing to invest and when you need the project completed. This will help narrow down your options.</li>
                  <li><span class="marked">Research vendors</span> Look for reputable outsourced web development companies. Check their portfolios, client testimonials, and areas of expertise.</li>
                  <li><span class="marked">Request proposals</span> Contact potential vendors with your project details and ask for detailed proposals.</li>
                  <li><span class="marked">Evaluate proposals</span> Compare the proposals based on cost, timeline, approach, and how well they understand your needs.</li>
                  <li><span class="marked">Check references</span> Don't hesitate to ask for and contact references to get insights into the company's performance and reliability.</li>
                  <li><span class="marked">Negotiate terms</span> Once you've chosen a vendor, discuss and agree on project specifics, including milestones, payment terms, and intellectual property rights.</li>
                  <li><span class="marked">Conduct a kick-off meeting</span> Start the project with a comprehensive meeting to ensure everyone is aligned on goals, expectations, and communication protocols.</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
    </section>
    <section class="faq-section">
      <h2>Frequently Asked Questions</h2>
        <div class="container-db">
          <div v-lazyload data-src="/img/images-db/services/lite-coin.png" class="lazy-bg bg-icon">
          </div>
          <div id="app">
            <app-accordion @closeAccordions='closeAccordions()' v-for="(accordion, index) in accordions"
                        ref="accordion" :key="index" class="app-accordion">
              <template v-slot:title>
                <span>{{ accordion.title }}</span>
              </template>
              <template v-slot:content>
                <p>
                  {{ accordion.text }}
                </p>
              </template>
            </app-accordion>
          </div>
        </div>
    </section>

    <h2 id="questions" class="more-questions">If you have any more questions, write to us</h2>
    <AcquaintedSection />
    <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>

    <div class="popup" :class="{ 'hide-popup': !showModal }">
      <div class="popup-wrapper">
        <div class="popup-inner" @click.self="closePopup()">
          <div class="popup-content" id="service-contact-form">
            <button class="close-popup" @click="closePopup()" type="button" aria-label="close popup"></button>
            <ServicesTopForm />
          </div>
        </div>
      </div>
    </div>

    <script v-html="jsonld" type="application/ld+json"></script>
  </main>
</template>
<script>
import AcquaintedSection from '../../AcquaintedSection';
import ServicesTopForm from '../../ServicesTopForm';
import AppAccordion from "./AppAccordion";
const Technologies = () => import("../../Home/Technologies");
const Company = () => import("../../Home/Company");

let vm = {};
export default {
  name: 'Outsource',
  metaInfo: {
    title: 'Outsource Web Development Company | DevBrother',
    meta: [
      {
        vmid: "description",
        name: 'description',
        content: 'Outsource software development and give your company an advantage over the competition. For agencies looking for peace of mind, high quality, and timely results.'
      },
      {
        vmid: "og:image:type",
        name: "og:image:type",
        content: 'image/webp'
      },
      {
        vmid: "og:title",
        name: "og:title",
        content: 'Outsource Web Development Company | DevBrother'
      },
      {
        vmid: "og:description",
        name: "og:description",
        content: 'Outsource software development and give your company an advantage over the competition. For agencies looking for peace of mind, high quality, and timely results.'
      },
      // {
      //   vmid: "og:image",
      //   name: "og:image",
      //   content: 'https://devbrother.com/img/images-db/images/e-commerce.webp'
      // },
      {
        vmid: "og:type",
        name: "og:type",
        content: "website"
      },
      // {
      //   vmid: "og:url",
      //   name: "og:url",
      //   content: 'https://devbrother.com/services/web-development'
      // },
      {
        vmid: "twitter:title",
        name: "twitter:title",
        content: 'Outsource Web Development Company | DevBrother'
      },
      {
        vmid: "twitter:description",
        name: "twitter:description",
        content: 'Outsource software development and give your company an advantage over the competition. For agencies looking for peace of mind, high quality, and timely results.'
      },
      // {
      //   vmid: "twitter:image",
      //   name: "twitter:image",
      //   content: 'https://devbrother.com/img/images-db/images/e-commerce.webp'
      // }
    ]
  },
  components: {
    AcquaintedSection,
    ServicesTopForm,
    AppAccordion,
    Technologies,
    Company,
  },
  data() {
    const jsonld = {
      "@context": "https://schema.org",
      "@type": "Service",
      "name": "Outsource Web Development Company | DevBrother",
      "description": "Outsource software development and give your company an advantage over the competition. For agencies looking for peace of mind, high quality, and timely results."
    }

    return {
      jsonld,
      showModal: false,
      accordions: [
        {
          title: 'How can you boost my business\' online presence?',
          text: "We create visually appealing, user-friendly websites optimized for search engines and implement effective digital marketing strategies to increase your visibility and attract more customers."
        },
        {
          title: 'What tools do you use for building websites?',
          text: "We use various tools depending on project requirements. These may include content management systems like WordPress, e-commerce platforms like Shopify, and custom development using frameworks like React or Angular."
        },
        {
          title: 'Are your websites SEO-friendly?',
          text: "Absolutely. We build websites using SEO best practices, ensuring proper structure, fast loading times, and mobile responsiveness. We can also provide ongoing SEO services to improve your search engine rankings."
        },
        {
          title: 'Can I hire you on a per project/per hour basis?',
          text: "Yes, we offer flexible engagement models. Whether you need a one-time project or ongoing support, we can tailor our services."
        },
        {
          title: 'What are the reasons for outsourcing web development services?',
          text: "Outsourcing web development can save costs, provide access to a wider talent pool, allow you to focus on core business activities, and bring fresh perspectives to your project."
        },
        {
          title: 'What are the benefits of outsourcing website development?',
          text: "Benefits include cost savings, faster project completion, access to specialized skills, scalability, and the ability to leverage the latest technologies without investing in training or equipment."
        },
      ],
    }
  },
  beforeCreate() {
    vm = this;
  },
    mounted: () => {
            vm.body = document.querySelector("body");
            vm.addCanonicalLink();
    },
    methods: {
            addCanonicalLink: () => {
              const link = document.createElement('link');
              link.setAttribute('rel', 'canonical');
              link.setAttribute('href', 'https://devbrother.com/services/web-development-outsourcing');

              const oldLink = document.querySelector('link[rel="canonical"]');
              if (oldLink) {
                document.head.removeChild(oldLink);
              }

              document.head.appendChild(link);
            },
            showPopup: () => {
              vm.showModal = true;
              vm.body.classList.add('ban-scroll');
            },
            closePopup: () => {
              vm.showModal = false;
              vm.body.classList.remove('ban-scroll');
            },
            scrollFix: hash => {
              setTimeout(() => {
                setTimeout(() => $('html, body')
                  .animate({ scrollTop: $(hash).offset().top }, 1000), 1)
              });
            },
            closeAccordions() {
              this.$refs.accordion.forEach(el => el.closeAccordion());
            },
  }
}
</script>
<style lang="scss" scoped>

.service-process .service-wrapper {
  justify-content: space-around;
}

.outsource-read-more {
  ::v-deep .company-header {
    display: none;
  }
}

.outsource-read-more-header {
  text-align: center;
  margin-bottom: -150px;
  margin-top: -50px;
}

::v-deep h5 {
  display: none;
}

.outsource-other {
  margin-top: 100px;
  h2 {
    text-align: center;
  }
}

.outsource-section {
  .faq-section {
    margin-top: -100px;
  }

  .more-questions {
    margin-top: 150px;
    text-align: center;
  }
}

.outsource-process {
  margin-top: 150px;
}

.outsource-services {
  margin: 0 auto;
}

.service-contact {
    max-height: 430px;
}

ol {
    padding: 0;
    list-style-type: none;

    li {
        position: relative;
        margin-bottom: 15px;
        padding-left: 35px;

        &::before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            top: 3px;
            left: 0;
            background: url(/img/images-db/services/check_circle_icon.svg) no-repeat;
            background-size: contain;
        }
    }
}

.service-description {

    a {
        color: #06c6ff;
        text-decoration: underline;

        &:hover {
            opacity: 0.85;
        }
    }

    .service-img-wrapper {
        max-width: 550px;

        img {
            filter: grayscale(0.6);
            border-radius: 20px;
        }
    }

    .advantages {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;

        h2 {
            text-align: center;
        }

        .bottom-info {
            margin-top: 30px;
        }
    }

    .key-benefits {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;

        h2 {
            text-align: center;
        }

        ol {
            width: 60%;
        }

        .img-wrap {
            width: 37%;
            margin-top: 0;
        }
    }
}

.marked {
    color: #06c6ff;
}

.service-text-wrapper {
    padding: 30px;
    border-radius: 40px;
    background: linear-gradient(342.18deg, #1b222a 0%, #2b506e 100%);
}

.text-img-list {
    max-width: 900px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;

    h3 {
        text-align: center;
        width: 100%;
    }

    .text-img-wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    li {
        padding-left: 0;

        &:not(:last-child) {
            margin-bottom: 50px;
        }

        &::before {
            display: none;
        }
    }

    .card-text {
        width: 50%;
        p{
            max-width: 425px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .img-wrap {
        width: 40%;
    }
}

.list-inner-wrap {
    display: flex;
    align-items: center;
}

.advantages {
    .btn {
        display: block;
        width: 200px;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        color: #1f2427;
        text-decoration: none;

        &:hover {
            color: #ffffff;
        }
    }
}

.life-cycle{
    img{
        min-height: 240px;
        max-height: 240px;
        object-fit: cover;
    }
}

.wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .img-wrap {
        max-width: 350px;
    }
}

@media (max-width: 1220px) {
    .top-form-section .service-text-wrapper {
        max-height: none;
    }

    .industry-top-form {
        margin-top: 30px;
    }

    .advantages {
        .img-wrap {
            margin-top: 0;
        }
    }
}

@media (max-width: 990px) {

    .health-technologies {
        li {
            width: 48%;
        }
    }

}

@media (max-width: 900px) {

    .text-img-list {

        h3 {
            text-align: center;
            width: 100%;
        }

        .text-img-wrap {
            flex-direction: column-reverse;
            align-items: center;

            &.inverted {
                flex-direction: column;
            }
        }

        .card-text {
            width: 100%;
        }

        .img-wrap {
            width: 100%;
        }
    }

    .img-wrap.service-img-wrapper {
        max-width: 400px;
        margin-top: 0;
        margin-bottom: 25px;
    }

    .inverted {
        flex-direction: column-reverse;
    }

    .service-description {
        .key-benefits {
            .wrap {
                flex-direction: column-reverse;
            }

            ol {
                width: 100%;
            }

            .img-wrap {
                max-width: 400px;
                width: 100%;
                margin-bottom: 25px;
            }
        }
    }

    .software-benefits{
        .img-wrap{
            max-height: 400px;
            width: auto!important;
            img{
                max-height: 400px;
            }
        }
    }
}

@media (max-width: 768px) {
    .bg-icon {
        display: none;
    }
}

@media (max-width: 767px) {
    .service-text-wrapper {
        padding: 30px 15px;
    }

    .health-technologies {
        h3 {
            font-size: 20px;
            letter-spacing: 0.45px;
            line-height: 30px;
        }

    }
}

@media (max-width: 550px) {

    h1 {
        font-size: 30px;
        line-height: 37px;
    }

    h2 {
        font-size: 26px;
    }

    .health-technologies {
        li {
            width: 100%;
        }

    }
}

.benefits .benefit-header {
  text-transform: none;
}

.how-to-section {
  margin-top: 100px;
}

</style>